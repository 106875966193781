import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { facebookConversionsApiRoute } from '../../common/constants/routes'
import { restClient } from '../../utilities/restClient'
import { sha256 } from '../../utilities/sha256'
// eslint-disable-next-line no-restricted-imports
import jsCookie from 'js-cookie'
import { prodHostname } from '../../common/constants/prodHostname.mjs'

export type ConversionEventInput = {
  event_name: 'CompleteRegistration' | 'Purchase' | 'AddToCart' | 'ViewContent'
  fbc?: string //_fbc browser cookie
  fbp?: string //_fbp browser cookie
  user_data?: {
    em?: string // email
    fn?: string // first name lowercase
    ln?: string // last name lowercase
    ph?: string
    external_id?: string // our realize id
    ge?: 'f' | 'm' | undefined
    db?: string // 19910526 for May 26, 1991.
    ct?: string // city
    st?: string // state
    zp?: string // zip
    country?: string // us
  }
  custom_data?: {
    currency: 'usd'
    value?: number
    contents: {
      id: string
      quantity: number
    }[]
  }
}
const serialize = (input?: string) => (input ? sha256(input.trim().toLowerCase()) : undefined)

const cleanObject = (obj: object) => omitBy(obj, isNil)

export function sendFacebookConversionEventFromClient(input: ConversionEventInput) {
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const event_time = Math.floor(Date.now() / 1000) // get current Unix timestamp in seconds

  const body = {
    ...(typeof window !== 'undefined' && location.hostname !== prodHostname && { test_event_code: 'TEST70146' }),
    data: [
      cleanObject({
        event_name: input.event_name,
        event_time,
        user_data: cleanObject({
          fbc: jsCookie.get('_fbc'),
          fbp: jsCookie.get('_fbp'),
          em: serialize(input.user_data?.em),
          external_id: input.user_data?.external_id,
          client_user_agent: navigator.userAgent,
        }),
        event_source_url: url,
        action_source: 'website',
        custom_data: input.custom_data,
      }),
    ],
  }

  return restClient(facebookConversionsApiRoute, { body })
}
