import { isRelativeOrAbsoluteUrlString } from '../../../typescript/guards/isRelativeOrAbsoluteUrlString'

export const getQueryParamObjectFromRelativeOrAbsoluteUrlString = (url: string): Record<string, string> => {
  if (!isRelativeOrAbsoluteUrlString(url)) return {}
  const params: Record<string, string> = {}
  const urlParams = new URLSearchParams(url.split('?')[1])

  urlParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
