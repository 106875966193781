import { useAutoUpdatingRef } from '../../../hooks/useAutoUpdatingRef'
import { useAccount } from '../../auth/hooks/useAccount'

export function useSharedFacebookConversionDataRef() {
  const { email, sub: id } = useAccount().user

  return useAutoUpdatingRef({
    user_data: {
      em: email,
      external_id: id,
    },
  })
}
