import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'
import { NextLink } from '../../../common/functionality/NextLink'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { transientOptions } from '../../../common/style/transientOptions'

export const SidebarLink = styled(NextLink, transientOptions)<{ $isActive: boolean; $nested?: boolean }>`
  display: block;
  width: 100%;
  padding: 16px ${(p) => (p.$nested ? 48 : 24)}px;
  font-weight: 400;
  line-height: 28px;
  color: ${theme.colors.primary900};
  background-color: ${(p) => (p.$isActive ? theme.colors.primary200 : theme.colors.grayscalewhite)};
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: ${(p) => (p.$isActive ? 0.8 : 0.5)};
  }
  font-size: ${(p) => (p.$nested ? '20px' : '22px')};

  ${tabletDownMq} {
    padding: 14px ${(p) => (p.$nested ? 44 : 22)}px;
  }
`
