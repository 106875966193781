import * as React from 'react'
import { useAccount } from '../auth/hooks/useAccount'
import { analytics } from './analytics'
import { parse, stringify } from 'flatted'
import { referralSources } from './common'
import { RealizeUser } from '../auth/RealizeUser'
import {
  isNewUserFirstLoggedInPageLoadSessionStorageKey,
  loginInitiatedAsSignupSessionStorageKey,
  referralSourceSessionStorageKey,
} from '../../common/constants/sessionStorage'

function useSegmentIdentifyUser() {
  const { user } = useAccount()

  const stringUser = stringify(user)
  React.useEffect(() => {
    const parsedUser: RealizeUser = parse(stringUser)
    if (parsedUser.id) {
      analytics.identify(parsedUser.id, parsedUser as object)
      analytics.track('User authenticated')
      if (parsedUser.isNewUserFirstLoggedInPageLoad) {
        analytics.track(
          `New sign up. Referrer: ${
            parsedUser.referralSource ?? referralSources.direct
          }. Log in initiated as sign up: ${parsedUser.loginInitiatedAsSignup}`
        )
        sessionStorage.removeItem(referralSourceSessionStorageKey)
        sessionStorage.removeItem(loginInitiatedAsSignupSessionStorageKey)
        sessionStorage.removeItem(isNewUserFirstLoggedInPageLoadSessionStorageKey)
      }
    }
  }, [stringUser])
}

export function SegmentIdentifyUser() {
  useSegmentIdentifyUser()
  return null
}
