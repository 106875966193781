import { isBuilt } from './isBuilt'
// I have never used findDOMNode. if it is in the console error message, it is about a third party library and there is
// nothing we can do to fix it easily.

const originals = Object.entries(console).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})

// TODO could eventually look into either switching libraries, adding some sort of workaround, or patching the libraries
export const filterConsole = () => {
  if (!isBuilt) {
    Object.keys(console).forEach((key) => {
      // @ts-ignore
      console[key] = (...args) => {
        const isDevIrrelevantRecoilWarning = args?.[0]?.name === 'Expectation Violation'
        if (isDevIrrelevantRecoilWarning) return

        const hasFindDomNode = args.some((x) => x?.includes?.('findDOMNode'))
        const hasStrictMode = args.some((x) => x?.includes?.('StrictMode.'))
        if (hasFindDomNode && hasStrictMode) return

        // @ts-ignore
        originals[key]?.(...args)
      }
    })
  }
}
