import { referralSourceQueryParamName } from '../../../common/constants/queryParams'
import { referralSourceSessionStorageKey } from '../../../common/constants/sessionStorage'

// immediately on app boot, set the referralSource in sessionStorage. when we get to login, delete it from session
// storage, and send it through in appState so we are clean in storage but can then access that on the other side if the
// flow actually ends in a new sign up

export function immediatelySetReferralSourceInSessionStorage() {
  if (typeof window !== 'undefined') {
    const referralSource =
      typeof window !== 'undefined' ? new URLSearchParams(location.search).get(referralSourceQueryParamName) : null
    if (referralSource) sessionStorage.setItem(referralSourceSessionStorageKey, referralSource)
  }
}
