import { Brand } from 'utility-types'
export type AbsoluteUrlStringBrand = Brand<string, 'absolute-url-string'>

export function isAbsoluteUrlString(input: any): input is AbsoluteUrlStringBrand {
  const isString = typeof input === 'string'
  if (!isString) return false

  try {
    new URL(input)
  } catch (_error) {
    return false
  }
  return true
}
