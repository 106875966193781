'use client'
import * as React from 'react'
import { DeviceProvider } from '../hooks/useDevice'
import { GoogleTagManager } from '@next/third-parties/google'
import { RecoilRoot } from 'recoil'
import { initReactAxe } from '../common/a11y/initReactAxe'
import { Modal } from '../features/modal/organisms/Modal'
import { MonitoringAndSyncing } from '../features/monitoring-and-syncing/MonitoringAndSyncing'
import { Footer } from '../features/navbar/atoms/Footer'
import { Navbar } from '../features/navbar/organisms/Navbar'
import { AppLayoutTemplate } from '../features/navbar/templates/AppLayoutTemplate'
import { Sidebar } from '../features/side-navigation/molecules/Sidebar'
import { filterConsole } from '../utilities/filterConsole'

import { ToastProvider } from '../features/toast/useToast'
import { useFixiOS100vhIssue } from '../styles/useFixiOS100vhIssue'
import { CookieProvider } from '../hooks/useCookies'
// TODO would like to implement this with the parser being qs so we can use nested objects. However, this library is
// using an older version of use-query-params which has either no way or a different/unknown way to pass in
// stringify/parser functions. either just update the library to use the new version (library is just a package of this
// thread's result https://github.com/pbeshai/use-query-params/issues/13) or figure out how to pass these functions in
// in the older version of use-query-params which next-query-params was built with
import { TrackpageViews } from '../features/analytics/TrackPageViews'
import { immediatelySetReferralSourceInSessionStorage } from '../features/analytics/utils/immediatelySetReferralSourceInSessionStorage'
import { EmotionRootStyleRegistry } from './EmotionRootStyleRegistry'
import NextAdapterApp from 'next-query-params/app'
import { QueryParamProvider } from 'use-query-params'
import { AuthProvider } from '../features/auth/providers/AuthProvider'
import { getPublicEnv } from '../env/getEnvs'
import { ApolloProvider } from '../apollo/client/ApolloProvider'
import { GlobalErrorBoundary } from '../features/errors/GlobalErrorBoundary'
import { useFranchiseDeploymentData } from '../features/account/features/franchise-membership/hooks/useHost/useFranchiseDeploymentData'

const { NEXT_PUBLIC_FRANCHISE_GTM_ID, NEXT_PUBLIC_GTM_ID } = getPublicEnv()

immediatelySetReferralSourceInSessionStorage()
filterConsole()
initReactAxe()

type Props = React.PropsWithChildren<{
  cookieString: string
  userAgentIsMobile: boolean
  isIos: boolean
  isAndroid: boolean
  isChrome: boolean
  isSafari: boolean
}>

export function AppRouterClientComponentWrappers({
  children,
  cookieString,
  userAgentIsMobile,
  isIos,
  isAndroid,
  isChrome,
  isSafari,
}: Props) {
  useFixiOS100vhIssue()

  const { isFranchiseUi } = useFranchiseDeploymentData()

  return (
    <GlobalErrorBoundary>
      <QueryParamProvider adapter={NextAdapterApp}>
        <EmotionRootStyleRegistry>
          <RecoilRoot>
            <CookieProvider cookieString={cookieString}>
              <ApolloProvider>
                <DeviceProvider
                  userAgentIsMobile={userAgentIsMobile}
                  isIos={isIos}
                  isAndroid={isAndroid}
                  isSafari={isSafari}
                  isChrome={isChrome}
                >
                  <ToastProvider>
                    <AuthProvider>
                      <MonitoringAndSyncing />
                      <AppLayoutTemplate
                        modal={<Modal />}
                        sidebar={<Sidebar />}
                        header={<Navbar />}
                        footer={<Footer />}
                        body={children}
                      />
                      <TrackpageViews />
                      <GoogleTagManager gtmId={isFranchiseUi ? NEXT_PUBLIC_FRANCHISE_GTM_ID : NEXT_PUBLIC_GTM_ID} />
                    </AuthProvider>
                  </ToastProvider>
                </DeviceProvider>
              </ApolloProvider>
            </CookieProvider>
          </RecoilRoot>
        </EmotionRootStyleRegistry>
      </QueryParamProvider>
    </GlobalErrorBoundary>
  )
}
