'use client'
import * as React from 'react'
import dynamic from 'next/dynamic'
import { useHasOccurred } from '../../../../hooks/useHasOccurred'

const ProgressBar = dynamic<{}>(() => import('./EagerProgressBar').then((x) => x.ProgressBar))

export function LazyProgressBar() {
  const [minimumAmountOfTimeHasPassed, setMinimumAmountOfTimeHasPassed] = React.useState(false)
  const [maximumAmountOfTimeHasPassed, setMaximumAmountOfTimeHasPassed] = React.useState(false)
  const [hasInteractedAfterMinimum, setHasInteractedAfterMinimum] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setMinimumAmountOfTimeHasPassed(true)
    }, 1500)
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setMaximumAmountOfTimeHasPassed(true)
    }, 5000)
  }, [])

  React.useEffect(() => {
    const handleInteraction = () => {
      if (minimumAmountOfTimeHasPassed) {
        setHasInteractedAfterMinimum(true)
      }
      // Remove event listeners once interaction is detected
      window.removeEventListener('click', handleInteraction)
      window.removeEventListener('resize', handleInteraction)
      window.removeEventListener('keydown', handleInteraction)
      window.removeEventListener('scroll', handleInteraction)
    }

    window.addEventListener('click', handleInteraction)
    window.addEventListener('resize', handleInteraction)
    window.addEventListener('keydown', handleInteraction)
    window.addEventListener('scroll', handleInteraction)

    return () => {
      window.removeEventListener('click', handleInteraction)
      window.removeEventListener('resize', handleInteraction)
      window.removeEventListener('keydown', handleInteraction)
      window.removeEventListener('scroll', handleInteraction)
    }
  }, [minimumAmountOfTimeHasPassed])

  const showProgressBar = minimumAmountOfTimeHasPassed && (maximumAmountOfTimeHasPassed || hasInteractedAfterMinimum)

  // proxy some amount of useful timeout for the progress bar so it doesn't load until it is useful by paying attention
  // to these events
  const progressbarShouldBeMounted = useHasOccurred(showProgressBar)
  return progressbarShouldBeMounted ? <ProgressBar /> : null
}
