import { useSharedFacebookConversionDataRef } from './hooks/useSharedFacebookConversionDataRef'
import { shopLabsRoute, shopMedicationsRoute, shopRoute, shopSupplementsRoute } from '../../common/constants/routes'
import { sendFacebookConversionEventFromClient } from './sendFacebookConversionEventFromClient'
import { analytics } from './analytics'
import { useOnRouteChangeComplete } from '../../hooks/useOnRouteChangeComplete'

const viewedContentFacebookPages = [shopRoute, shopLabsRoute, shopSupplementsRoute, shopMedicationsRoute]

const viewedContentFacebookPageRegexes = [
  new RegExp(`${shopMedicationsRoute}\\/[a-zA-Z0-9-_]+`),
  new RegExp(`${shopSupplementsRoute}\\/[a-zA-Z0-9-_]+`),
]

function useTrackPageViews() {
  const sharedFacebookConversionDataRef = useSharedFacebookConversionDataRef()

  useOnRouteChangeComplete((url: string) => {
    analytics.track(`Page View - ${document.title}`, {
      page: location.pathname,
      title: document.title,
      search: location.search,
      url: location.href,
    })

    if (
      viewedContentFacebookPages.some((x) => url.split('?')[0] === x) ||
      viewedContentFacebookPageRegexes.some((x) => x.test(url))
    ) {
      sendFacebookConversionEventFromClient({ ...sharedFacebookConversionDataRef.current, event_name: 'ViewContent' })
    }
  })
}

export function TrackpageViews() {
  useTrackPageViews()
  return null
}
