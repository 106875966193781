'use client'
import * as React from 'react'
import styled from '@emotion/styled'
import { UnstyledButton } from '../../common/style/UnstyledButton'
import { hoverOpacity } from '../../common/style/HoverOpacityWrapper'
import { homeRoute } from '../../common/constants/routes'
import { theme } from '../../styles/theme'
import { useRouter } from 'next/navigation'
import { AxoLogo } from '../../assets/branding/AxoLogo'
import { ErrorBoundary } from './ErrorBoundary'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 20vw;
`

const Text = styled.div`
  margin-top: 40px;
`

const Cta = styled(UnstyledButton)`
  font-size: 22px;

  ${hoverOpacity}
`

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const Divider = styled.div`
  height: 24px;
  width: 2px;
  background: ${theme.colors.grayCoal};
  margin: 0 15px;
`

export function GlobalErrorBoundary({ children }: React.PropsWithChildren<{}>) {
  // eslint-disable-next-line regex/invalid-error
  const { push, back } = useRouter()
  return (
    <ErrorBoundary
      fallback={function Fallback({ resetError }) {
        return (
          <Container>
            <AxoLogo style={{ scale: 4, marginBottom: 100 }} />
            <>
              <Text>We encountered an error.</Text>
              <CtaContainer>
                <Cta
                  onClick={() => {
                    resetError()
                    push(homeRoute)
                  }}
                >
                  Home
                </Cta>
                <Divider />
                <Cta
                  onClick={() => {
                    resetError()
                    back()
                  }}
                >
                  Back
                </Cta>
              </CtaContainer>
            </>
          </Container>
        )
      }}
    >
      {children}
    </ErrorBoundary>
  )
}
