// TODO check if we care about whether the referral sources actually match these. right now, all this actually is used
// for is to default the unknown referrers as 'direct'

export enum referralSources {
  direct = 'direct',
  rm_landing_page = 'rm_landing_page',
  rm_action_plan_advertising__join_circle_button = 'rm_action_plan_advertising__join_circle_button',
  rm_action_plan_advertising__action_plan_button = 'rm_action_plan_advertising__action_plan_button',
  email_invitation = 'email_invitation',
  circle_invitation = 'circle_invitation',
}
