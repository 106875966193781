import { isBuilt } from '../../../utilities/isBuilt'

export function trackFakeUserCountInDev(email?: string) {
  if (!isBuilt) {
    const previous = localStorage.getItem('dev-fake-user-count')
    const [, previousCount] = (previous ?? '').match(/(\d+)/) ?? []
    const [, currentCount] = (email ?? '').match(/\+(\d+)@/) ?? []
    if ((!previousCount && currentCount) || (previousCount && currentCount && +currentCount > +previousCount)) {
      localStorage.setItem('dev-fake-user-count', currentCount)
    }
  }
}
