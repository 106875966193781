import * as React from 'react'
import { useDevTools } from '../../../hooks/useDevTools'
import { useGetMasqueradingUserDataQuery } from '../../../generated/admin-queries'
import { enum_membership_tier_enum } from '../../../generated/graphql'
import { StateSetter } from '../../../typescript/utility'

export type MasqueradingOverrides = { membershipTier: enum_membership_tier_enum } | null

const useMasqueradingUserDataOverrides = () => {
  const [{ apiAccountOverride }] = useDevTools()

  const [overrideData, setOverrideData] = React.useState<MasqueradingOverrides>(null)

  useGetMasqueradingUserDataQuery({
    variables: { id: apiAccountOverride?.id ?? '' },
    skip: !apiAccountOverride?.id,
    onCompleted: (res) =>
      setOverrideData(res.user_by_pk?.membership_tier ? { membershipTier: res.user_by_pk.membership_tier } : null),
    onError: () => setOverrideData(null),
  })

  React.useEffect(() => {
    if (!apiAccountOverride?.id) setOverrideData(null)
  }, [apiAccountOverride])

  return overrideData
}

type Props = { setMasqueradingOverrides: StateSetter<MasqueradingOverrides> }

export function SyncMasqueradingUserData({ setMasqueradingOverrides }: Props) {
  const overrideData = useMasqueradingUserDataOverrides()

  React.useEffect(() => {
    setMasqueradingOverrides(overrideData)
  }, [overrideData, setMasqueradingOverrides])

  return null
}
