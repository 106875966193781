'use client'
import * as React from 'react'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'

type UseCountdownInput = {
  startCountdown?: boolean | null | undefined | 0 | ''
  step?: number
  countdownTime: number
  onComplete?: () => void
  reset?: () => void
  endTime?: number
}
/**
 * hook for counting down to zero from a given start time. begins countdown when startCountdown is truthy and takes an
 * onComplete callback. Takes in the initial countdown time (countdownTime) and an optional step param in ms, which
 * defaults to 1s. Currently just runs countdown once, but could be made updatable if we need it
 */
export function useCountdown({ startCountdown, countdownTime, onComplete, step = 1000 }: UseCountdownInput) {
  const [countdown, setCountdown] = React.useState(countdownTime)

  const onCompleteRef = useAutoUpdatingRef(onComplete)

  const countdownComplete = countdown === 0

  React.useEffect(() => {
    if (countdownComplete && typeof onCompleteRef.current === 'function') {
      onCompleteRef.current()
    }
  }, [countdownComplete, onCompleteRef])

  React.useEffect(() => {
    let unmounted: boolean
    let interval: NodeJS.Timeout | undefined
    if (startCountdown) {
      interval = setInterval(() => {
        if (!unmounted) {
          setCountdown((s) => (s ? s - 1 : 0))
        }
      }, step)
    } else {
      if (interval) clearInterval(interval)
    }
    return () => {
      unmounted = true
      if (interval) clearInterval(interval)
    }
  }, [startCountdown, step])
  return { countdown, countdownComplete }
}
