import { useAuth0 } from '@auth0/auth0-react' // eslint-disable-line no-restricted-imports
import { ReactSDKClient, withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk'
import * as React from 'react'
import { setTokenCookie } from '../../../common/constants/cookies'
import { sessionWasAuthedCookieName } from '../../../common/constants/cookies'
import {
  isNewUserSessionStorageKey,
  labUserNewAccountModalCompleteSessionStorageKey,
  realizeAdminSpoofedUserSessionStorageKey,
} from '../../../common/constants/sessionStorage'
import { useHasOccurred } from '../../../hooks/useHasOccurred'
import { SegmentIdentifyUser } from '../../analytics/SegmentIdentifyUser'
import { AccountContext } from '../hooks/useAccount'
import { RealizeUser } from '../RealizeUser'
import { RoleProvider } from './RoleProvider'
import { trackFakeUserCountInDev } from '../utils/trackFakeUserCountInDev'
import { useCookies } from '../../../hooks/useCookies'
import { type MasqueradingOverrides, SyncMasqueradingUserData } from '../atoms/SyncMasqueradingUserData'

const defaultUser = RealizeUser.init()

const useCreateUserContextFromAuth0AndSyncOptimizely = (optimizely: ReactSDKClient | null) => {
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0()
  const hasLoaded = useHasOccurred(!isLoading)

  const { set, remove } = useCookies()

  React.useEffect(() => {
    if (!auth0User?.sub) setTokenCookie()
  }, [auth0User])

  const user = React.useMemo(() => {
    if (isLoading) return defaultUser
    if (isAuthenticated && auth0User?.sub) {
      return RealizeUser.fromAuth0User(auth0User)
    } else if (!isAuthenticated && !isLoading) {
      return defaultUser
    }
    return defaultUser
  }, [auth0User, isAuthenticated, isLoading])

  // track the side effect values in an effect, and not in the above useMemo (despite their triggers being the same) as setting state in the render method is a
  // soft error
  React.useEffect(() => {
    if (isLoading) return
    if (isAuthenticated && auth0User?.sub) {
      trackFakeUserCountInDev(auth0User.email)
      set(sessionWasAuthedCookieName, 'true')
    } else if (!isAuthenticated && !isLoading) {
      remove(sessionWasAuthedCookieName)
      sessionStorage.removeItem(realizeAdminSpoofedUserSessionStorageKey)
      sessionStorage.removeItem(isNewUserSessionStorageKey)
      sessionStorage.removeItem(labUserNewAccountModalCompleteSessionStorageKey)
    }
  }, [auth0User, isAuthenticated, isLoading, remove, set])

  React.useEffect(() => {
    if (hasLoaded && optimizely) {
      optimizely.setUser({ id: user.id || 'ANONYMOUS', attributes: user })
    }
  }, [user, hasLoaded, optimizely])

  return user
}

export const AccountProvider = withOptimizely(
  ({ children, optimizely }: React.PropsWithChildren<WithOptimizelyProps>) => {
    const user = useCreateUserContextFromAuth0AndSyncOptimizely(optimizely)
    const auth0Context = useAuth0()

    const [masqueradingOverridesData, setMasqueradingOverridesData] = React.useState<MasqueradingOverrides>(null)

    return (
      <AccountContext.Provider
        value={{ ...auth0Context, user: RealizeUser.create({ ...user, ...masqueradingOverridesData }) }}
      >
        <RoleProvider roles={[user.defaultRole]}>
          <SegmentIdentifyUser />
          <SyncMasqueradingUserData setMasqueradingOverrides={setMasqueradingOverridesData} />
          {children}
        </RoleProvider>
      </AccountContext.Provider>
    )
  }
)
