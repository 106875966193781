import * as React from 'react'
import throttle from 'lodash/throttle'
import { isPortraitMq } from './mediaQueries'
import { isInPwaStandaloneMode } from '../utilities/isInPwaStandaloneMode'
import { visibleScreenHeightVarName } from './visibleScreenHeightVarName'

export const useFixiOS100vhIssue = () => {
  React.useEffect(() => {
    const portrait = window.matchMedia(isPortraitMq)
    const setVisibleScreenHeight = throttle(() => {
      document.documentElement.style.setProperty(visibleScreenHeightVarName, `${window.innerHeight}px`)
    }, 50)
    portrait.addEventListener('change', setVisibleScreenHeight)
    window.addEventListener('resize', setVisibleScreenHeight)
    setVisibleScreenHeight()
    // on pwa, screen size seems to change, but resize is not being fired i don't think. just force a calculation every
    // 1000
    // !!update: the screen orientation listener above may have rendered this unnecessary. need to test though
    if (isInPwaStandaloneMode) setInterval(setVisibleScreenHeight, 1000)
  }, [])
}
