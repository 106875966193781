'use client'
import * as React from 'react'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'

export function useConditionHasBeenMetForXTime({ condition, wait }: { condition: any; wait: number }) {
  const [ready, setReady] = React.useState(false)

  const conditionIsTruthy = !!condition
  const conditionIsTruthyRef = useAutoUpdatingRef(conditionIsTruthy)
  React.useEffect(() => {
    let mounted = true
    if (conditionIsTruthy) {
      setTimeout(() => {
        if (mounted) setReady(conditionIsTruthyRef.current)
      }, wait)
    } else {
      setReady(false)
    }
    return () => {
      mounted = false
    }
  }, [conditionIsTruthy, wait, conditionIsTruthyRef])
  return ready
}
