'use client'
import * as React from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import NProgress from 'nprogress'
import { useApolloNetworkStatus } from '../../../../apollo/hooks/useApolloNetworkStatus'
import { useAutoUpdatingRef } from '../../../../hooks/useAutoUpdatingRef'

// !! This component cannot be lazy loaded. if it is, replace/push functions will change upon this being loaded, which,
// if used in an effect, will retrigger the effect. if we decide to lazy load, then we will need to make some sort of
// custom useRouter hook which returns out stable functions for each which both access referenced versions of the
// original functions such that even after lazy loading, the returned functions are still stable throughout
const useLinkApolloNetworkStatusWIthProgress = () => {
  const { numPendingQueries, numPendingMutations } = useApolloNetworkStatus()
  const queriesPending = !!(numPendingQueries || numPendingMutations)
  const queriesPendingRef = useAutoUpdatingRef(queriesPending)

  React.useEffect(() => {
    if (!queriesPending) {
      NProgress.done()
    } else {
      // clear previous in case it was already running so it restarts from the beginning
      NProgress.set(0)
      NProgress.start()
    }
  }, [queriesPending])
  return { queriesPendingRef }
}

const usePatchRouterForProgress = () => {
  const router = useRouter()

  const { push, replace } = router

  const originalPush = React.useRef(push)
  const originalreplace = React.useRef(replace)

  router.push = React.useCallback((href, options) => {
    NProgress.start()
    originalPush.current(href, options)
  }, [])

  router.replace = React.useCallback((href, options) => {
    NProgress.start()
    originalreplace.current(href, options)
  }, [])
}

// !!MUST ADD TO TOP LEVEL COMPONENT SO THE ROUTER IS PATCHED PRIOR TO BEING ACCESSED BY ANY OTHER COMPONENT!!
export function PatchRouterForProgressBar() {
  usePatchRouterForProgress()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { queriesPendingRef } = useLinkApolloNetworkStatusWIthProgress()

  React.useEffect(() => {
    // add a small timeout for the first render/queries to start running after route change before checking whether
    // anything is in flight/finishing progress bar
    setTimeout(() => {
      if (!queriesPendingRef.current) {
        NProgress.done()
      }
    }, 50)
  }, [pathname, searchParams, queriesPendingRef])
  return null
}
