import { theme } from '../../styles/theme'

export function AxoLogo({
  color = theme.colors.primary700,
  width,
  height,
  ...rest
}: {
  color?: string
} & React.SVGProps<SVGSVGElement>) {
  const finalWidth = width && height ? width : typeof height === 'number' ? height * 1.8823529412 : 64

  const finalHeight = height && width ? height : typeof width === 'number' ? width * 0.53125 : 34
  return (
    <svg width={finalWidth} height={finalHeight} viewBox="0 0 64 34" fill="none" {...rest}>
      <g id="Logo">
        <g id="Vector">
          <path d="M0.773117 32.8935H5.93872L11.1025 1.10684H5.93872L0.773117 32.8935Z" fill={color} />
          <path d="M16.2681 1.10684L11.1025 1.10684L16.2681 32.8935H21.4337L16.2681 1.10684Z" fill={color} />
          <path
            d="M21.1772 1.10684H26.3428L31.5084 17.0002L26.3428 32.8935H21.1772L26.3428 17.0002L21.1772 1.10684ZM36.6722 1.10684H41.8378L36.6722 17.0002L41.8378 32.8935H36.6722L31.5066 17.0002L36.6722 1.10684Z"
            fill={color}
          />
          <path
            d="M41.7714 17.0002C41.7714 5.0416 44.7516 0.47168 52.4992 0.47168C60.2467 0.47168 63.2269 5.0416 63.2269 17.0002C63.2269 28.9588 60.2467 33.5287 52.4992 33.5287C44.7516 33.5287 41.7714 28.9588 41.7714 17.0002ZM52.4992 28.6017C56.5918 28.6017 57.9429 25.7004 57.9429 17.0002C57.9429 8.29993 56.5918 5.39865 52.4992 5.39865C48.4065 5.39865 47.0554 8.29993 47.0554 17.0002C47.0554 25.7004 48.4065 28.6017 52.4992 28.6017Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}
