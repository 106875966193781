import { sessionWasAuthedCookieName } from '../../common/constants/cookies'
import jsCookie from 'js-cookie' // eslint-disable-line no-restricted-imports
import { NextExpressPageContext } from '../../typescript/utility'
export const isRealizeAdminEmailRegex = /(.+@realize.me)$/

export const getUserIsLikelyInAuthedSession = (ctx?: NextExpressPageContext) =>
  typeof window !== 'undefined'
    ? jsCookie.get(sessionWasAuthedCookieName) === 'true'
    : ctx
      ? ctx?.req?.cookies?.[sessionWasAuthedCookieName] === 'true'
      : false
