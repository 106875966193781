'use client'
import * as React from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'

export function useOnRouteChangeComplete(onRouteChangeComplete: (newUrl: string) => void) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const url = `${pathname}?${searchParams}`

  const onRouteChangeCompleteRef = useAutoUpdatingRef(onRouteChangeComplete)

  const [urlHasChanged, setUrlHasChanged] = React.useState(false)
  const urlOnMount = React.useRef(url)

  const urlHasChangedRef = useAutoUpdatingRef(urlHasChanged)

  React.useEffect(() => {
    const urlDifferentThanMount = urlOnMount.current !== url

    if (urlDifferentThanMount && !urlHasChangedRef.current) {
      setUrlHasChanged(true)
    }
    // if this runs, it means the url dep was the trigger. if urlOnMount.current !== url, we obviously want to run the
    // callback. however, we also want to run it if urlHasChangedRef.current is true, because that means we are not on
    // the first run, and the url has changed (it just changed back to the mount url). thus, run in that case as well.
    // only time we don't run is if url has yet to change and urlOnMount.current === url
    if (urlHasChangedRef.current || urlOnMount.current !== url) {
      onRouteChangeCompleteRef.current(url)
    }
  }, [url, onRouteChangeCompleteRef, urlHasChangedRef])

  return null
}
