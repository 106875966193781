'use client'

import { useEffect } from 'react'
import { resetServerContext } from 'react-beautiful-dnd'

export const ResetDndServerContext = () => {
  useEffect(() => {
    resetServerContext()
  }, [])

  return null
}
