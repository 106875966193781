'use client'
import * as React from 'react'

export function useHasOccurred(input: any) {
  const ref = React.useRef(false)
  if (input) {
    ref.current = true
  }
  return ref.current
}
