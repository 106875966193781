'use client'
import * as React from 'react'
import { useAutoUpdatingRef } from './useAutoUpdatingRef'
import { useUrl } from './useUrl'

export function useOnNavigationComplete(onNavigate: (url: string) => void) {
  const cb = useAutoUpdatingRef(onNavigate)

  const url = useUrl()

  React.useEffect(() => {
    cb.current(url)
  }, [cb, url])
}
