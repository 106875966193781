// eslint-disable-next-line no-restricted-imports
import { captureException, ErrorBoundaryProps, ErrorBoundary as SentryNextJsErrorBoundary } from '@sentry/nextjs'

export const ErrorBoundary = (props: ErrorBoundaryProps) => (
  <SentryNextJsErrorBoundary
    {...props}
    onError={(error, componentStack, eventId) => {
      props.onError?.(error, componentStack, eventId)
      captureException(error, { extra: { componentStack } })
    }}
  />
)
