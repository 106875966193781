'use client'
import * as React from 'react'

import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { useServerInsertedHTML } from 'next/navigation'
import { useState } from 'react'

export function EmotionRootStyleRegistry({ children }: React.PropsWithChildren<{}>) {
  const [state] = useState(() => {
    const cache = createCache({ key: 'emotion-cache' })
    cache.compat = true
    const prevInsert = cache.insert
    type InsertInput = Parameters<typeof cache.insert>

    let inserted: string[] = []
    cache.insert = (...args: InsertInput) => {
      const serialized = args[1]
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name)
      }
      return prevInsert(...args)
    }
    const flush = () => {
      const prevInserted = inserted
      inserted = []
      return prevInserted
    }
    return { cache, flush }
  })

  useServerInsertedHTML(() => {
    const names = state.flush()
    if (names.length === 0) return null
    let styles = ''
    for (const name of names) {
      styles += state.cache.inserted[name]
    }
    return (
      <style
        data-emotion={`${state.cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    )
  })

  return <CacheProvider value={state.cache}>{children}</CacheProvider>
}
